<template>
  <div id="page" class="h-100">
    <div>
      <img src="@/assets/images/tada-logo.svg" alt="Tada.vote">
    </div>
  </div>
</template>

<script>

export default {
  title(){
      return process.env.VUE_APP_TITLE;
  },
  name: 'HomeView'
}
</script>



<style scoped lang="scss">
#page {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>