import axios from 'axios';
import apiService from './apiService';
import cookieService from './cookieService';
import languageService from './languageService';

export default class authService {

    static signOut () {
        cookieService.removeToken();
    }


    static isAuth (sessionSlug) {
        
        return new Promise((resolve, reject) => {
            axios.get(process.env.VUE_APP_API_URL+"/voter/isAuth/"+sessionSlug, {
                headers: {
                'Authorization': 'Bearer ' + cookieService.getToken(),
                "Content-Type": "application/json",
                }
            })
            .then(response => {
                
                if(response.data.language)
                {
                    languageService.setLanguage(response.data.language);    
                }

                if(response.data.token)
                {
                    cookieService.setToken(response.data.token);
                }
                
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
         });
       
    }
 


    static signinEmail (email, password, session) {

        let data = {
            email: email,
            password: password,
            session: session.id
        }; 
        return new Promise((resolve, reject) => {
            axios.post(process.env.VUE_APP_API_URL+"/voter/signinEmail", data, {
                headers: {
                'Authorization': 'Bearer ' + process.env.VUE_APP_API_BEARER_TOKEN,
                "Content-Type": "application/json",
                }
            })
            .then(response => {
                if(response.data.language)
                {
                    languageService.setLanguage(response.data.language);    
                }
                
                if(response.data.token)
                {
                    cookieService.setToken(response.data.token);
                }

                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            });
         });
    
        
    }


    static signinExternal (externalId, session) {

        let data = {
            externalId: externalId,
            session: session.id
        }; 
        return new Promise((resolve, reject) => {
            
            apiService.post("/voter/signinExternal/", data, false)
            .then(response => {
                if(response.data.language)
                {
                    languageService.setLanguage(response.data.language);    
                }
                
                if(response.data.token)
                {
                    cookieService.setToken(response.data.token);
                }

                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
         });
    
        
    }



    static signinPublic (session) {

        let data = {
            session: session.id
        }; 
        return new Promise((resolve, reject) => {
            
            apiService.post("/voter/signinPublic/", data, false)
            .then(response => {
                if(response.data.language)
                {
                    languageService.setLanguage(response.data.language);    
                }
                
                if(response.data.token)
                {
                    cookieService.setToken(response.data.token);
                }

                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
         });
    
        
    }


    static signinExternalBySlug (externalId, sessionSlug) {

        let data = {
            externalId: externalId,
            sessionSlug: sessionSlug
        }; 
        return new Promise((resolve, reject) => {
            
            apiService.post("/voter/signinExternalBySlug/", data, false)
            .then(response => {
                if(response.data.language)
                {
                    languageService.setLanguage(response.data.language);    
                }

                if(response.data.token)
                {
                    cookieService.setToken(response.data.token);
                }
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
         });
    
        
    }

    static signinPublicBySlug (sessionSlug) {

        let data = {
            sessionSlug: sessionSlug
        }; 
        return new Promise((resolve, reject) => {
            
            apiService.post("/voter/signinPublicBySlug/", data, false)
            .then(response => {
                if(response.data.language)
                {
                    languageService.setLanguage(response.data.language);    
                }

                if(response.data.token)
                {
                    cookieService.setToken(response.data.token);
                }
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
         });
    
        
    }    

    static getSocketParameters (sessionSlug, channelSlug) {

        let data = {
            channelSlug: channelSlug,
            sessionSlug: sessionSlug
        }; 
        return new Promise((resolve, reject) => {
            
            apiService.post("/voter/getSocketParametersBySlugs/", data)
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
         });
    
        
    }    


}
