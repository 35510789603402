<template>
  <div v-if="session" id="page" class="container-fluid min-h-100">
    <navbar-component :session="session" />
    <question-component v-if="documentSocket && documentSocket.documentType == documentSocketType.QUESTION" :session="session" :documentSocket="documentSocket" :voter="voter" />
    <qas-component v-if="documentSocket && documentSocket.documentType == documentSocketType.QAS" :session="session" :documentSocket="documentSocket" />
    <screen-component v-if="documentSocket && documentSocket.documentType == documentSocketType.SCREEN" :session="session" :documentSocket="documentSocket" />

    <div  v-if="!documentSocket && !error">
      <p class="text-center mb-5"><img v-if="session.logo" class="w-25" :src="session.logo" alt=""></p>
      <p class="text-center mb-3" :style="[{'color':session.textColor}]">{{ $t("session.home.waiting_message") }}</p>
    </div>

    <div v-if="error">
      <p class="text-center mb-5"><img v-if="session.logo" :src="session.logo" width="192" alt=""></p>
      <h5 class="text-center mb-3" :style="[{'color':session.textColor}]">{{ $t("session.home.error") }}</h5>
      <p class="text-center" v-html="error" :style="[{'color':session.textColor}]"></p>
    </div>
  </div>
  <div v-if="sessionNotOpened" id="page" class="container-fluid min-h-100">
    <div class="text-center">
      <h5 class="mb-3">{{ $t("session.home.error") }}</h5>
      <p>{{ $t("session.home.error_session_not_opened") }}</p>
      <button class="btn btn-primary" @click="beforeLoadParameters">{{ $t("session.home.retry") }}</button>
    </div>
  </div>
  <div v-if="signinPublicError" id="page" class="container-fluid min-h-100">
    <div class="text-center">
      <h5 class="mb-3">{{ $t("session.home.error") }}</h5>
      <p>{{ $t("session.home.error_signin_public") }}</p>
      <button class="btn btn-primary" @click="beforeLoadParameters">{{ $t("session.home.retry") }}</button>
    </div>
  </div>  


  <disconnected-component />

</template>


<script>

import NavbarComponent from '@/components/navigation/NavbarComponent.vue';
import QuestionComponent from '@/components/question/QuestionComponent.vue';
import QasComponent from '@/components/qas/QasComponent.vue';
import ScreenComponent from '@/components/screen/ScreenComponent.vue';
import DisconnectedComponent from '@/components/DisconnectedComponent.vue';

import socketConfig from '@/configs/socketConfig';
import DocumentSocketType from '@/model/socket/DocumentSocketType';
import DocumentSocketStatus from '@/model/socket/DocumentSocketStatus';
import authService from '@/services/authService';
import socketService from '@/services/socketService';
import eventDispatcher from '@/eventDispatcher';
import eventConfig from '@/configs/eventConfig';

export default 
  {
    title(){
    if(this.session)
    {
      return this.session.name;
    }else
    {
      return process.env.VUE_APP_TITLE;
    }
  },
  name: 'SessionView',
  components: {
    'navbar-component': NavbarComponent,
    'question-component': QuestionComponent,
    'qas-component': QasComponent,
    'screen-component': ScreenComponent,
    'disconnected-component': DisconnectedComponent
  },
  data: function () {
    return {
      error: null,
      socket: null,
      voter: null,
      room: null,
      session: null,
      documentSocketType: DocumentSocketType,
      documentSocketStatus: DocumentSocketStatus,
      documentSocket: null,
      sessionNotOpened: false,
      signinPublicError: false
    }
  },
  watch: 
  { 
    '$route.params.channelSlug': {
        handler: function() {
           this.beforeLoadParameters();
        },
        deep: true,
        immediate: true
      }
  },
  mounted()
  {
    
  },
  methods:
  {
    beforeLoadParameters()
    {
      this.sessionNotOpened = false;
      this.signinPublicError = false;

      if(this.$route.params.externalId)
      {
        authService.signinExternalBySlug(this.$route.params.externalId, this.$route.params.sessionSlug).then(
        response => {
          this.loadParameters();
        },
        error => {
          if(error.response.data.msg_code == "api.voter.isAuth.session_not_opened")
          {
            this.sessionNotOpened = true;
          }else
          {
            this.$router.push({ name: 'Signin', params: {sessionSlug: this.$route.params.sessionSlug, channelSlug: this.$route.params.channelSlug} });
          }
        }
      );
      }else
      {
        authService.isAuth(this.$route.params.sessionSlug).then(
        response => {
          
          this.loadParameters();
        },
        error => {
          if(error.response.data)
          {
            if(error.response.data.msg_code == "api.voter.isAuth.session_not_opened")
            {
              this.sessionNotOpened = true;
            }else
            {
              this.$router.push({ name: 'Signin', params: {sessionSlug: this.$route.params.sessionSlug, channelSlug: this.$route.params.channelSlug} });
            }
          }else
          {
            eventDispatcher.emit(eventConfig.CONNECTION_REFUSED);
          }
        }
        );
      }
    },
    loadParameters()
    {
      authService.getSocketParameters(this.$route.params.sessionSlug, this.$route.params.channelSlug).then(
        response => {
          
          this.session = response.session;
          

          this.voter = response.voter;
          
          if(response.channel)
          {

            this.room = response.channel.id;
            
            if(this.socket)
            {
              this.socket.disconnect();
            }
            this.connectSocket();
          }else
          {
            this.error = this.$t("session.home.channel_unknown", { channel: this.$route.params.channelSlug});
          }
        },
        errors => {

          
        }
      );
      
    },
    connectSocket()
    {
      socketService.connect();

      eventDispatcher.on(socketConfig.CONNECT, () => {
        socketService.emit(socketConfig.JOIN_SESSION, this.room);
      });

      eventDispatcher.on(socketConfig.PILOT_SESSION, (documentSocket) => {
        this.documentSocket = documentSocket;
        
      });
      
      
    }
  }
}

</script>

<style scoped lang="scss">
#page {
  display: flex;
  align-items: center;
  justify-content: center;

}

.bar {
  flex-wrap: initial !important;
}

.bar h6 {
  color: white;
}

.question {
  font-size: 20px;

}

.radio, .checkbox {
  background-color: #F5F5F5;
  padding: 15px;
  border-radius: 20px;
}

.radio.active, .checkbox.active {
  background-color: black;
}

.radio label input, .checkbox label input {
  display: none;
}

.radio label span, .checkbox label span {
  line-height: 22px;
  font-size: 20px;
}

.radio label, .checkbox label {
  padding-left: 32px;
  background-position-y: 1px;
  background-repeat: no-repeat;
}

.radio label {
  background-image: url(@/assets/images/radio-off.svg);
}

.checkbox label {
  background-image: url(@/assets/images/checkbox-off.svg);
}

.radio.active label, .checkbox.active label {
  color: white;
}

.radio.active label {
  background-image: url(@/assets/images/radio-on.svg);
}

.checkbox.active label {
  background-image: url(@/assets/images/checkbox-on.svg);
}

.instructions {
  font-size: 14px;
  font-style: italic;
  color: #666666;
}

.message {
  font-size: 16px;
  font-weight: 700;
}


</style>