<template>
  <div id="page" class="container-sm h-100 d-flex">
    <div v-if="session" class="justify-content-center align-self-center d-block w-100 logo">
      <img :src="session.logo" class="mx-auto d-block mb-4" alt="">
      <p class="lead text-center">{{ $t("passwordChange.instructions") }}</p>
      <form @submit.prevent="changePassword">
        
        <div class="form-group mb-3">
          <label for="passwordLostInput">{{ $t("passwordChange.your_new_password") }}</label>
          <input type="password" class="form-control" :class="{ 'is-invalid': passwordError }" id="passwordLostInput" v-model="password">
          <div v-if="passwordError" class="invalid-feedback">
            {{passwordError}}
          </div>
        </div>

        <div class="form-group mb-3">
          <label for="passwordLostInput2">{{ $t("passwordChange.your_new_password_again") }}</label>
          <input type="password" class="form-control" :class="{ 'is-invalid': passwordError }" id="passwordLostInput2" v-model="password2">
        </div>        

        <div v-if="error" class="alert alert-danger" role="alert">
          {{error}}
        </div>

        <div v-if="success" class="alert alert-success" role="alert">
          {{success}}
        </div>

        
        
        <button v-if="!success" type="submit" class="btn" :style="[{'color':session.elementTextActiveColor},{'background-color':session.elementBackgroundActiveColor}]">{{ $t("passwordChange.change_password") }}</button>
        <button v-if="success" type="button" class="btn btn-light underline" @click="gotoSignin()">{{ $t("passwordChange.back_to_signin") }}</button>
      </form>
    
    </div>
  </div>

</template>


<script>

import voterService from '@/services/voterService';
import sessionService from '@/services/sessionService';

export default {
  title(){
      return process.env.VUE_APP_TITLE;
  },
  name: 'PasswordChangeView',
  data: function () {
    return {
      password: null,
      passwordError: null,
      password2: null,
      password2Error: null,
      error: null,
      success: null,
      session: null
    }
  },
  mounted(){
    this.loadSession();
  },
  methods: {
    gotoSignin() {
      this.$router.push({ name: 'Signin', params: {sessionSlug: this.$route.params.sessionSlug, channelSlug: this.$route.params.channelSlug} });
    },
    parseErrors(data)
    {
      if(data.errors)
      {
        for(let error of data.errors)
        {
          
          switch(error.param)
          {
            case 'password':
              this.passwordError = (error.msg.msg_code) ? this.$t(error.msg.msg_code) : error.msg;
            break;
            case 'password2':
              this.password2Error = (error.msg.msg_code) ? this.$t(error.msg.msg_code) : error.msg;
            break;
            
          }
        }
      }else
      {
        this.error = data.msg;
      }
    },
     controlForm() {
      var success = true;
      
        if( !this.password || this.password != this.password2)
        {
          success = false;
          this.passwordError = this.$t("passwordChange.must_fill_password_two_times");
        }
      

      return success;
    },
    loadSession() {
      sessionService.getBySlug( this.$route.params.sessionSlug).then(
          response => {
            this.session = response;
          },
          errors => {
            
            
          }
        );
    },
    changePassword() {
      this.emailError = null;
      this.passwordError = null;
      this.error = null;

      if(this.controlForm())
      {
        voterService.changePassword(this.password, this.$route.params.token).then(
          response => {
            this.success = this.$t("passwordChange.password_changed");
          },
          errors => {
            if(errors.msg)
            {
              this.parseErrors({msg: errors.msg});
            }else
            {
              this.parseErrors(errors.response.data);
            }
            
          }
        );
      }
      
    }


  }
}
</script>

<style scoped lang="scss">


.logo img {
  max-width: 192px;
}


</style>