import { createApp } from 'vue'

import "bootstrap"

import App from './App.vue'
import router from './router'
import i18n from './i18n'
import eventDispatcher from './eventDispatcher'
import vueVimeoPlayer from 'vue-vimeo-player'
import titleMixin from './mixins/titleMixin'


import './sass/app.scss'

const app = createApp(App)
app.mixin(titleMixin);

app.use(i18n);
app.use(router);
app.use(vueVimeoPlayer)


app.config.globalProperties.eventDispatcher = eventDispatcher;
app.mount('#app');