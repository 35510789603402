import apiService from './apiService';

export default class voteService {



    static send (vote) {
        
        return new Promise((resolve, reject) => {
            
            apiService.post("/vote/send", vote)
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
         });
       
    }

    static next (question) {
        
        return new Promise((resolve, reject) => {
            
            apiService.post("/vote/next", question)
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
         });
       
    } 
    
    static previous (question) {
        
        return new Promise((resolve, reject) => {
            
            apiService.post("/vote/previous", question)
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
         });
       
    }     



}
