<template>
  
  <div v-if="active" class="popin d-flex align-items-center justify-content-center flex-column">
    <div v-if="!reload" class="text-center">
      <h5 class="pb-2">{{$t("disconnected.searching_connection")}}</h5>
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <div v-if="reload" class="text-center">
      <h5 class="pb-2">{{$t("disconnected.unable_access_server")}}</h5>
      <a class="btn btn-primary" @click="retry()">{{$t("disconnected.retry")}}</a>
    </div>    
  </div>
  
</template>

<script>
import eventDispatcher from "@/eventDispatcher";
import socketConfig from '@/configs/socketConfig';
import eventConfig from "@/configs/eventConfig";

export default {
  name: 'DisconnectedComponent',
  data: function () {
    return {
      active: false,
      reload: false
    }
  },
  mounted() {
    eventDispatcher.on(socketConfig.DISCONNECT, (reason) => {
          
         this.active = true;

         if ( (reason === "io server disconnect") || (reason === "io client disconnect") ) {
            // you need to reconnect manually
            this.reload = true;
          }
        
    });

    eventDispatcher.on(eventConfig.CONNECTION_REFUSED, () => {
          this.active = true;
          this.reload = true;
     });

    eventDispatcher.on(socketConfig.CONNECT, () => {
          
          this.active = false;
 
     });

    
  },
  methods: {
    retry()
    {
      window.location.reload();
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .popin {
    z-index: 1040;
    width: 100% !important;
    height: 100% !important;
    background-color: rgba(255,255,255,0.8);
    position: absolute;
    top: 0;
    left: 0;
  }
</style>
