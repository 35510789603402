<template>
    
    <div class="col col-12">
      
      <div class="content">
        <p class="question" :style="[{'color':session.textColor}]">
          {{ documentSocket.documentData.question.text }}
        </p>
      </div>

      <div class="answers">
          <div v-if="documentSocket.documentData.question.data && documentSocket.documentData.question.data.present && documentSocket.documentData.question.data.present.introduction" class="help" :style="[{'color':session.helpColor}]"  v-html="documentSocket.documentData.question.data.present.introduction"></div>

          <div v-for="answer in documentSocket.documentData.answers" v-bind:key="answer.id" class="result mt-3 mb-3">
            <div class="header d-flex justify-content-between">
             
              <div class="label" :style="[{'color':session.textColor}]">{{answer.text}}</div>

            </div>
          </div>

      </div>

    </div>

</template>

<script>

import socketConfig from '@/configs/socketConfig';
import eventDispatcher from "@/eventDispatcher";
import socketService from "@/services/socketService";

export default {
  name: 'PresentBasicComponent',
  props: {
    documentSocket: Object,
    voter: Object,
    session: Object
  }
}

</script>

<style scoped>

.question {
  font-size: 20px;

}

.result {
  background-color: #F5F5F5;
  padding: 20px;
  border-radius: 20px;
  color: #707070;
}



.help {
  font-size: 14px;
  font-style: italic;
  color: #666666;
}

.message {
  font-size: 16px;
  font-weight: 700;
}

.header .label {
    font-size: 20px;
    line-height: 22px;

}


.votes {
  
  font-size: 16px;
}

.result.active .label, .result.active .votes {
  color: black;
}


.data .value {
  width: 100%;
  height: 22px;
  background-color: #707070;
  border-radius: 11px;
}

.result.active .data .value {
  background-color: black;
}




.data .label {
  margin-right: -15px;
  float: right;
}

</style>