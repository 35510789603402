
import { createRouter, createWebHistory } from 'vue-router'

import HomeView from '../views/HomeView.vue';
import SigninView from '../views/SigninView.vue';
import SessionView from '../views/SessionView.vue';
import LiveSessionView from '../views/LiveSessionView.vue';
import Page404View from '../views/Page404View.vue';
import PasswordChangeView from '../views/PasswordChangeView.vue';
import SignupConfirmView from '../views/SignupConfirmView.vue';
import authService from '../services/authService';
import redirectService from '../services/redirectService';
import eventDispatcher from '../eventDispatcher';
import eventConfig from '@/configs/eventConfig';

const defaultMeta = {
  hasMark: true
};

const requireAuthMeta = {
  requiresAuth: true,
  hasMark: true
};

const liveMeta = {
  hasMark: false
};


const routes = [
  {
    path: '/',
    name: 'Home', 
    meta: defaultMeta,
    component: HomeView
  },
  {
    path: '/live/:sessionSlug/:channelSlug/:vimeoId/:vimeoH/:externalId?',
    name: 'LiveSession',
    component: LiveSessionView,
    meta: liveMeta
  },  
  {
    path: '/:sessionSlug/:channelSlug/signin',
    name: 'Signin', 
    meta: defaultMeta,
    component: SigninView
  },
  {
    path: '/:sessionSlug/:channelSlug/passwordChange/:token',
    name: 'PasswordChange', 
    meta: defaultMeta,
    component: PasswordChangeView
  },
  {
    path: '/:sessionSlug/:channelSlug/signupConfirm/:token',
    name: 'SignupConfirm', 
    meta: defaultMeta,
    component: SignupConfirmView
  },  
  {
    path: '/:sessionSlug/:channelSlug/:externalId?',
    name: 'Session',
    component: SessionView,
    meta: defaultMeta
  },
  { path: '/:pathMatch(.*)*', name:"Page404", component: Page404View }
];


var router = createRouter({
  history: createWebHistory(),
  routes
})
//$t("signin.external.id_label")
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    
    authService.isAuth(to.params.sessionSlug).then(
      response => {
        next();
      },
      error => {

        redirectService.setRedirectRoute(to);
        next({ name: 'Signin', params: {sessionSlug: to.params.sessionSlug, channelSlug: to.params.channelSlug, error: "router.please_signin"} });
      }
    );

  }else{
    
        next();
  }


});

eventDispatcher.on(eventConfig.UNAUTHORIZED, () => {
  router.push({ name: 'Signin', params: {error: "router.please_signin"} });
})

export default router
