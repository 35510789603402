<template>
  

    <div class="row">
      <div class="col col-12">
        <div class="logo text-center">
          <img v-if="session.logo" :src="session.logo">
        </div>
        <div class="content text-center">
          <p class="title" :style="[{'color':session.textColor}]">
            {{session.name}}
          </p>
          <p class="text" :style="[{'color':session.textColor}]">
            {{ $t("signin.external.fill_connection_code") }}
          </p>
        </div>

        <div class="">
          <form @submit.prevent="signin">  
            <div class="form-group mb-3">
              <label for="externalIdInput" :style="[{'color':session.textColor}]">{{ $t("signin.external.id_label") }}</label>
              <input id="externalIdInput" type="externalId" class="form-control" :class="{ 'is-invalid': externalIdError }" v-model="externalId">
              <div v-if="externalIdError" class="invalid-feedback">
                {{externalIdError}}
              </div>          
            </div>

            <div v-if="$route.params.error" class="alert alert-danger" role="alert">
              {{ $t($route.params.error) }}
            </div>

            <div v-if="error" class="alert alert-danger" role="alert">
              {{error}}
            </div>
            
            <div class="text-end">
              <button type="submit" class="btn" :style="[{'color':session.elementTextActiveColor},{'background-color':session.elementBackgroundActiveColor}]">{{ $t("signin.external.enter_label") }}</button>
            </div>
          </form>
        </div>

      </div>

    </div>
    
  
  
</template>

<script>

import sessionService from '@/services/sessionService';
import authService from '@/services/authService';
import redirectService from '@/services/redirectService';

export default {
  name: 'SigninExternalComponent',
  props: {
    session: Object,
    channelSlug: String
  },
  data: function () {
    return {
      error: null,
      externalIdError: null,
      externalId: null
    }
  },
  mounted(){
    
  },
  methods: {
    parseErrors(data)
    {
      if(data.errors)
      {
        for(let error of data.errors)
        {
          
          switch(error.param)
          {
            case 'externalId':
              this.externalIdError = (error.msg.msg_code) ? this.$t(error.msg.msg_code) : error.msg;
            break;
            
          }
        }
      }else
      {
        this.error = (data.msg_code) ? this.$t(data.msg_code) : data.msg;
      }
    },  
    controlForm() {
      if(this.externalId)
      {
        return true;
      }else
      {
        this.externalIdError = this.$t("signin.external.code_required_label");
        return false;
        
      }
    }, 
    signin() {

      this.$route.params.error = null;
      this.externalIdError = null;
      this.error = null;

      if(this.controlForm())
      {
        

        authService.signinExternal(this.externalId, this.session).then(
          response => {
            if(redirectService.getRedirectRoute())
            {
              this.$router.push(redirectService.getRedirectRoute().path); 
              redirectService.removeRedirectRoute();
            }else
            {
              this.$router.push({ name: 'Session', params: {sessionSlug: this.session.slug, channelSlug: this.channelSlug, externalId: this.externalId} }); 
            }
            
          },
          errors => {
            this.parseErrors(errors.response.data);
          }
        );
      }
    }


  }
}
</script>




<style scoped lang="scss">


.logo img {
  max-width: 192px;
}


</style>