import axios from 'axios';
import cookieService from './cookieService';
import eventDispatcher from '../eventDispatcher'

import eventConfig from '@/configs/eventConfig';

export default class apiService {


    static get (url, useUserToken=true) {
        
        return new Promise((resolve, reject) => {
            axios.get(process.env.VUE_APP_API_URL+url, {
                headers: {
                'Authorization': 'Bearer ' +((useUserToken) ? cookieService.getToken() : process.env.VUE_APP_API_BEARER_TOKEN),
                "Content-Type": "application/json",
                }
            })
            .then(response => {
                resolve(response);
            })
            .catch(function (error) {
                console.log(error);
                if(error.response.status == 401)
                {
                    eventDispatcher.emit(eventConfig.UNAUTHORIZED);
                }else
                {
                    reject(error);
                }
                
            });
         });
       
    }


    static post (url, data, useUserToken=true) {
        
        return new Promise((resolve, reject) => {
           
            axios.post(process.env.VUE_APP_API_URL+url, data, {
                headers: {
                'Authorization': 'Bearer ' + ((useUserToken) ? cookieService.getToken() : process.env.VUE_APP_API_BEARER_TOKEN),
                "Content-Type": "application/json",
                }
            })
            .then(response => {
                resolve(response);
            })
            .catch(function (error) {
                if(error.response.status == 401)
                {
                    eventDispatcher.emit(eventConfig.UNAUTHORIZED);
                }else
                {
                    reject(error);
                }
            });
         });
       
    }


    static delete (url, useUserToken=true) {
        
        return new Promise((resolve, reject) => {
            axios.delete(process.env.VUE_APP_API_URL+url, {
                headers: {
                'Authorization': 'Bearer ' + ((useUserToken) ? cookieService.getToken() : process.env.VUE_APP_API_BEARER_TOKEN),
                "Content-Type": "application/json",
                }
            })
            .then(response => {
                resolve(response);
            })
            .catch(function (error) {
                if(error.response.status == 401)
                {
                    eventDispatcher.emit(eventConfig.UNAUTHORIZED);
                }else
                {
                    reject(error);
                }
            });
         });
       
    }  


}
