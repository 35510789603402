<template>
    <screen-text-component v-if="documentSocket && documentSocket.documentData.template.componentName == 'ScreenTextComponent'" :documentSocket="documentSocket" :session="session" />
    <screen-title-text-component v-if="documentSocket && documentSocket.documentData.template.componentName == 'ScreenTitleTextComponent'" :documentSocket="documentSocket" :session="session" />
    <screen-title-text-image-component v-if="documentSocket && documentSocket.documentData.template.componentName == 'ScreenTitleTextImageComponent'" :documentSocket="documentSocket" :session="session" />
</template>

<script>
import ScreenTextComponent from '@/components/screen/ScreenTextComponent.vue';
import ScreenTitleTextComponent from '@/components/screen/ScreenTitleTextComponent.vue';
import ScreenTitleTextImageComponent from '@/components/screen/ScreenTitleTextImageComponent.vue';

export default {
  name: 'ScreenComponent',
  data: function () {
    return {
      error: null
    }
  },
  components:
  {
    'screen-text-component': ScreenTextComponent,
    'screen-title-text-component': ScreenTitleTextComponent,
    'screen-title-text-image-component': ScreenTitleTextImageComponent,
  },
  props: {
    documentSocket: Object,
    groups: Array,
    session: Object
  },
  mounted()
  {
    
  }
}

</script>

<style scoped lang="scss">
</style>