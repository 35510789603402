<template>
  

    <div class="row">
      <div class="col col-12">
        <div class="logo text-center">
          <img v-if="session.logo" :src="session.logo">
        </div>
        <div class="content text-center">
          <p class="title" :style="[{'color':session.textColor}]">
            {{session.name}}
          </p>
          <p class="text" :style="[{'color':session.textColor}]">
          {{ $t("signin.email.join_message") }}
          </p>
        </div>

        <div class="">
          <form @submit.prevent="signin">  
            <div class="form-group mb-3">
              <label for="emailInput" :style="[{'color':session.textColor}]">{{ $t("signin.email.email_label") }}</label>
              <input id="emailInput" type="email" class="form-control" :class="{ 'is-invalid': emailError }" v-model="email">
              <div v-if="emailError" class="invalid-feedback">
                {{emailError}}
              </div>          
            </div>

            <div class="form-group mb-3">
              <label for="passwordInput" :style="[{'color':session.textColor}]">{{ $t("signin.email.password_label") }}</label>
              <input id="passwordInput" type="password" class="form-control" :class="{ 'is-invalid': passwordError }" v-model="password">
              <div v-if="passwordError" class="invalid-feedback">
                {{passwordError}}
              </div>          
            </div>

            <div class="form-group mb-3 underline text-end">
              <a @click="passwordLost()">{{ $t("signin.email.forgotten_password") }}</a>
            </div>

            <div v-if="$route.params.error" class="alert alert-danger" role="alert">
              {{ $t($route.params.error) }}
            </div>

            <div v-if="error" class="alert alert-danger" role="alert">
              {{error}}
            </div>
            
            <div class="text-end">
              <button type="submit" class="btn" :style="[{'color':session.elementTextActiveColor},{'background-color':session.elementBackgroundActiveColor}]">{{ $t("signin.email.enter_label") }}</button>
            </div>
          </form>
        </div>

      </div>

    </div>
    
  <password-lost-component @canceled="passwordLostEnded" :action="passwordLostAction" :session="session" :channelSlug="channelSlug" />
  
</template>

<script>

import authService from '@/services/authService';
import redirectService from '@/services/redirectService';
import PasswordLostComponent from '@/components/signin/PasswordLostComponent.vue'

export default {
  name: 'SigninEmailComponent',
  components: {
    'password-lost-component': PasswordLostComponent
  },
  props: {
    session: Object,
    channelSlug: String
  },
  data: function () {
    return {
      error: null,
      emailError: null,
      passwordError: null,
      email: null,
      password: null,
      passwordLostAction: false
    }
  },
  mounted(){
    
  },
  methods: {
    parseErrors(data)
    {
      if(data.errors)
      {
        for(let error of data.errors)
        {
          
          switch(error.param)
          {
            case 'email':
              this.emailError = (error.msg.msg_code) ? this.$t(error.msg.msg_code) : error.msg;
            break;
            case 'password':
              this.passwordError = (error.msg.msg_code) ? this.$t(error.msg.msg_code) : error.msg;
            break;
          }
        }
      }else
      {
        this.error = (data.msg_code) ? this.$t(data.msg_code) : data.msg;
      }
    },  
    passwordLost()
    {
      this.passwordLostAction = true;
    },
    passwordLostEnded()
    {
      this.passwordLostAction = false;
    },
    controlForm() {
      if(this.email && this.password)
      {
        return true;
      }else
      {
        this.error = this.$t("signin.email.empty_email_password");
        return false;
        
      }
    }, 
    signin() {

      this.$route.params.error = null;
      this.emailError = null;
      this.passwordError = null;
      this.error = null;

      if(this.controlForm())
      {
        

        authService.signinEmail(this.email, this.password, this.session).then(
          response => {
            
            if(redirectService.getRedirectRoute())
            {
              this.$router.push(redirectService.getRedirectRoute().path); 
              redirectService.removeRedirectRoute();
            }else
            {
              this.$router.push({ name: 'Session', params: {sessionSlug: this.session.slug, channelSlug: this.channelSlug} }); 
            }
            
          },
          errors => {
            this.parseErrors(errors.response.data);
          }
        );
      }
    }


  }
}
</script>




<style scoped lang="scss">


.logo img {
  max-width: 192px;
}


</style>