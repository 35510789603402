<template>
    <present-component v-if="documentSocket && documentSocket.documentStatus == documentSocketStatus.PRESENT" :documentSocket="documentSocket" :session="session" />
    <vote-component v-if="documentSocket && documentSocket.documentStatus == documentSocketStatus.VOTE" :documentSocket="documentSocket" :voter="voter" :session="session" />
    <result-component v-if="documentSocket && documentSocket.documentStatus == documentSocketStatus.RESULT" :documentSocket="documentSocket" :session="session" />
</template>

<script>
import DocumentSocketStatus from '@/model/socket/DocumentSocketStatus';
import PresentComponent from '@/components/question/PresentComponent.vue';
import VoteComponent from '@/components/question/VoteComponent.vue';
import ResultComponent from '@/components/question/ResultComponent.vue';

export default {
  name: 'QuestionComponent',
  components: {
    'present-component': PresentComponent,
    'vote-component': VoteComponent,
    'result-component': ResultComponent,
  },
  data: function () {
    return {
      error: null,
      documentSocketStatus: DocumentSocketStatus
    }
  },
  props: {
    documentSocket: Object,
    voter: Object,
    session: Object
  },
  mounted()
  {
  
  },
  methods:
  {
    
  }
}

</script>


<style scoped lang="scss">
#page {
  display: flex;
  align-items: center;
  justify-content: center;

}

.bar {
  flex-wrap: initial !important;
}

.bar h6 {
  color: white;
}

.question {
  font-size: 20px;

}

.radio, .checkbox {
  background-color: #F5F5F5;
  padding: 15px;
  border-radius: 20px;
}

.radio.active, .checkbox.active {
  background-color: black;
}

.radio label input, .checkbox label input {
  display: none;
}

.radio label span, .checkbox label span {
  line-height: 22px;
  font-size: 20px;
}

.radio label, .checkbox label {
  padding-left: 32px;
  background-position-y: 1px;
  background-repeat: no-repeat;
}

.radio label {
  background-image: url(@/assets/images/radio-off.svg);
}

.checkbox label {
  background-image: url(@/assets/images/checkbox-off.svg);
}

.radio.active label, .checkbox.active label {
  color: white;
}

.radio.active label {
  background-image: url(@/assets/images/radio-on.svg);
}

.checkbox.active label {
  background-image: url(@/assets/images/checkbox-on.svg);
}

.instructions {
  font-size: 14px;
  font-style: italic;
  color: #666666;
}

.message {
  font-size: 16px;
  font-weight: 700;
}


</style>