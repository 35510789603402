<template>
  <div class="row w-100 after-navbar">
   <present-basic-component v-if="documentSocket && documentSocket.documentData.question.presentTemplate.componentName == 'PresentBasicComponent'" :documentSocket="documentSocket" :session="session" />
 </div>
 
</template>

<script>
import PresentBasicComponent from '@/components/question/PresentBasicComponent.vue'


export default {
name: 'PresentComponent',
components: {
 'present-basic-component': PresentBasicComponent
},
props:
{
 documentSocket: Object,
 voter: Object,
 session: Object
}
}

</script>