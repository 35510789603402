<template>
    
    <div class="col col-12">
      <div class="content">
        <p class="question" :style="[{'color':session.textColor}]">
          {{ documentSocket.documentData.question.text }}
        </p>
      </div>

      <div class="answers">
          <div v-if="documentSocket.documentData.question.data && documentSocket.documentData.question.data.result && documentSocket.documentData.question.data.result.introduction" class="help" :style="[{'color':session.helpColor}]"  v-html="documentSocket.documentData.question.data.result.introduction"></div>

          <div v-for="answer in documentSocket.documentData.answers" v-bind:key="answer.id" class="result mt-3 mb-3" :class="{'active': getAnswerVotes(answer).isBest}">
            <div class="header d-flex justify-content-between mb-3">
              <div v-if="getAnswerVotes(answer).isBest" class="label" :style="[{'color':session.textActiveColor}]">{{answer.text}}</div>
              <div v-if="!getAnswerVotes(answer).isBest" class="label" :style="[{'color':session.textColor}]">{{answer.text}}</div>

              <div v-if="getAnswerVotes(answer).isBest" class="votes flex-shrink-0" :style="[{'color':session.textActiveColor}]">
                x{{getAnswerVotes(answer).number}}
                <svg id="ico-user" xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
                  <path id="Path_10" data-name="Path 10" d="M18.778,3.222A11,11,0,0,0,3.222,18.778,11,11,0,0,0,18.778,3.222ZM11,20.711a9.689,9.689,0,0,1-7.238-3.245A7.736,7.736,0,0,1,11,12.461a3.867,3.867,0,1,1,3.867-3.867A3.867,3.867,0,0,1,11,12.461a7.736,7.736,0,0,1,7.238,5.005A9.689,9.689,0,0,1,11,20.711Z" transform="translate(0 0)" :fill="session.textActiveColor"/>
                </svg>
              </div>
              <div v-if="!getAnswerVotes(answer).isBest" class="votes flex-shrink-0" :style="[{'color':session.textColor}]">
                x{{getAnswerVotes(answer).number}}
                <svg id="ico-user" xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
                  <path id="Path_10" data-name="Path 10" d="M18.778,3.222A11,11,0,0,0,3.222,18.778,11,11,0,0,0,18.778,3.222ZM11,20.711a9.689,9.689,0,0,1-7.238-3.245A7.736,7.736,0,0,1,11,12.461a3.867,3.867,0,1,1,3.867-3.867A3.867,3.867,0,0,1,11,12.461a7.736,7.736,0,0,1,7.238,5.005A9.689,9.689,0,0,1,11,20.711Z" transform="translate(0 0)" :fill="session.textColor"/>
                </svg>
              </div>
            </div>
            <div class="data mb-3" :style="{ width: getAnswerVotes(answer).percent + '%' }">
              <div v-if="getAnswerVotes(answer).isBest" class="value" :style="[{'background-color':session.elementBackgroundActiveColor}]"></div>
              <div v-if="!getAnswerVotes(answer).isBest" class="value" :style="[{'background-color':session.elementBackgroundColor}]"></div>
              
              <div v-if="getAnswerVotes(answer).isBest" class="label" :style="[{'color':session.textActiveColor}]">{{getAnswerVotes(answer).percent}}%</div>
              <div v-if="!getAnswerVotes(answer).isBest" class="label" :style="[{'color':session.textColor}]">{{getAnswerVotes(answer).percent}}%</div>
            </div>
          </div>

          <div v-if="documentSocket.documentData.question.absenceCounted" class="result mt-3 mb-3" :class="{'active': getAnswerVotes({id:undefined}).isBest}">
            <div class="header d-flex justify-content-between mb-3">
              <div class="label" :style="[{'color':session.textColor}]">{{documentSocket.documentData.question.labelAbstention}}</div>
              <div class="votes flex-shrink-0" :style="[{'color':session.textColor}]">
                x{{getAnswerVotes({id:undefined}).number}}
                <svg id="ico-user" xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
                  <path id="Path_10" data-name="Path 10" d="M18.778,3.222A11,11,0,0,0,3.222,18.778,11,11,0,0,0,18.778,3.222ZM11,20.711a9.689,9.689,0,0,1-7.238-3.245A7.736,7.736,0,0,1,11,12.461a3.867,3.867,0,1,1,3.867-3.867A3.867,3.867,0,0,1,11,12.461a7.736,7.736,0,0,1,7.238,5.005A9.689,9.689,0,0,1,11,20.711Z" transform="translate(0 0)" :fill="session.textColor"/>
                </svg>
              </div>
            </div>
            <div class="data mb-3" :style="{ width: getAnswerVotes({id:undefined}).percent + '%' }">
              <div class="value" :style="[{'background-color':session.elementBackgroundColor}]"></div>
              <div class="label" :style="[{'color':session.textColor}]">{{getAnswerVotes({id:undefined}).percent}}%</div>
            </div>
          </div>

      </div>

    </div>

</template>

<script>
import gsap from "gsap";
import socketConfig from '@/configs/socketConfig';
import { io } from 'socket.io-client';
import eventDispatcher from "@/eventDispatcher";
import eventConfig from "@/configs/eventConfig";
import socketService from "@/services/socketService";

export default {
  name: 'ResultBasicComponent',
  props: {
    documentSocket: Object,
    voter: Object,
    session: Object
  },
  data: function () {
    return {
      stats: null
    }
  },
  watch: 
  { 
    documentSocket: function() {

      
        this.setSocket();
      
    }
  },
  mounted() {

    this.setSocket();
      
      
  },
  deactivated() {


  },
  methods:
  {
    isAnswerVotesChoice(answer)
    {
      return true;
    },
    getAnswerVotes(answer)
    {
      var minChoice = this.documentSocket.documentData.question.minChoice;
      var maxChoice = this.documentSocket.documentData.question.minChoice;

      if(!this.stats)
      {
        return {
          number: 0,
          percent: 0,
          isBest: false
        }
      }

      var number = this.stats.answers[answer.id];
      if(number == null)
      {
        number = 0;
      }

      var percent = number * 100 / this.stats.total;
      if(isNaN(percent))
      {
        percent = 0;
      }else
      {
        percent = Math.round(percent*100)/100;
      }

      var bestScore = 0;
      var bestAnswers = [];

      for (const [key, value] of Object.entries(this.stats.answers)) {
        if(value > bestScore)
        {
          bestAnswers = [key];
          bestScore = value;
        }else if(value == bestScore)
        {
          bestAnswers.push(key);
        }
      
      }
      
      var isBest = false;
      if(bestAnswers.includes(answer.id))
      {
        isBest = true;
      }

      return {
        number: number,
        percent: percent,
        isBest: isBest
      }
    },
    setSocket()
    {
      

      socketService.emit(socketConfig.JOIN_VOTE, this.documentSocket.documentData.question.id);
       
      eventDispatcher.off(socketConfig.PILOT_VOTE);
      eventDispatcher.on(socketConfig.PILOT_VOTE, (stats) => {
        this.stats = stats;
      });
        
      
    }
  }
}

</script>

<style scoped>

.question {
  font-size: 20px;

}

.result {
  background-color: #F5F5F5;
  padding: 20px;
  border-radius: 20px;
  color: #707070;
}



.help {
  font-size: 14px;
  font-style: italic;
  color: #666666;
}

.message {
  font-size: 16px;
  font-weight: 700;
}

.header .label {
    font-size: 20px;
    line-height: 22px;

}


.votes {
  
  font-size: 16px;
}

.result.active .label, .result.active .votes {
  color: black;
}


.data .value {
  width: 100%;
  height: 22px;
  background-color: #707070;
  border-radius: 11px;
}

.result.active .data .value {
  background-color: black;
}




.data .label {
  margin-right: -15px;
  float: right;
}

</style>