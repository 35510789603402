<template>
  <div v-if="!session && !notfound" class="h-100 d-flex justify-content-center align-items-center">
    <div class="spinner-border" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
  <div v-if="session && !notfound" id="page" class="container-fluid h-100">
    <signin-external-component v-if="state == 'external'" :session="session" :channelSlug="$route.params.channelSlug" />
    <signin-email-component v-if="state == 'email'" :session="session" :channelSlug="$route.params.channelSlug" />
  </div>
  <div v-if="notfound" id="page" class="container-fluid h-100">
    <message-404-component></message-404-component>
  </div>

</template>


<script>

import sessionConfig from '@/configs/sessionConfig';
import sessionService from '@/services/sessionService';
import SigninExternalComponent from '@/components/signin/SigninExternalComponent.vue';
import SigninPublicComponent from '@/components/signin/SigninPublicComponent.vue';
import SigninEmailComponent from '@/components/signin/SigninEmailComponent.vue';
import Message404Component from '@/components/404/message404Component.vue';
import authService from '@/services/authService';

export default {
  title(){
    if(this.session)
    {
      return this.session.name;
    }else
    {
      return process.env.VUE_APP_TITLE;
    }
  },
  components: { 
    'signin-external-component': SigninExternalComponent, 
    'signin-email-component': SigninEmailComponent,
    Message404Component 
  },
  name: 'SigninView',
  data: function () {
    return {
      state: 'external',
      error: null,
      session: null,
      notfound: false
    }
  },
  mounted(){
    this.loadSession();
  },
  methods: {
    parseErrors(data)
    {
     
        this.error = data.msg;
        
    },
    signinPublic() {
      authService.signinPublicBySlug(this.$route.params.sessionSlug).then(
        response => {
          this.$router.push({ name: 'Session', params: {sessionSlug: this.$route.params.sessionSlug, channelSlug: this.$route.params.channelSlug} });
        },
        errors => {
          
        }
        );
    },
    loadSession() {
      
      sessionService.getBySlug(this.$route.params.sessionSlug).then(
        response => {
          var session = response;
          
          this.session = session;

          if(session)
          {
            switch(session.signin)
            {
              case sessionConfig.SIGNIN_EXTERNALID:
                this.state = 'external';
              break;
              case sessionConfig.SIGNIN_EMAIL:
                this.state = 'email';  
              break;
              case sessionConfig.SIGNIN_PUBLIC:
                this.state = 'public';  
                this.signinPublic();
              break;
            }

          
          }else
          {
            this.notfound = true;
          }
    
        },
        errors => {
          this.error = errors;
        }
      );
    }

  }
}
</script>




<style scoped lang="scss">
#page {
  display: flex;
  align-items: center;
  justify-content: center;

}


</style>