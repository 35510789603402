<template>

  <div class="col col-12">
    <div class="content">
      <p class="question" :style="[{'color':session.textColor}]">
        {{ documentSocket.documentData.question.text }}
      </p>
    </div>
    <div v-if="documentSocket.documentData.question.data && documentSocket.documentData.question.data.vote && documentSocket.documentData.question.data.vote.introduction" class="help" :style="[{'color':session.helpColor}]" v-html="documentSocket.documentData.question.data.vote.introduction"></div>
    <div class=""  v-if="documentSocket.documentData.answers.length > 0">
      <form @submit.prevent="submit">   

        
          <div v-for="answer in documentSocket.documentData.answers" v-bind:key="answer.id">
            <label class="checkbox mt-3 mb-3" :style="[{'background-color': isChecked(answer) ? session.elementBackgroundActiveColor : ''}]" :class="[{'disabled': voteSended}]">
            
              <svg v-if="isChecked(answer)" xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
                <g id="checkbox-on" transform="translate(-21 -15)">
                  <g id="Rectangle_7" data-name="Rectangle 7" transform="translate(21 15)" fill="none" :stroke="session.elementTextActiveColor" stroke-width="2">
                    <rect width="22" height="22" rx="5" stroke="none"/>
                    <rect x="1" y="1" width="20" height="20" rx="4" fill="none"/>
                  </g>
                  <g id="ico-check" transform="translate(26 -33.188)">
                    <path id="Path_9" data-name="Path 9" d="M11.648,57.3l-6.33,6.524a1.176,1.176,0,0,1-1.7,0L.351,60.457a1.265,1.265,0,0,1,0-1.751,1.176,1.176,0,0,1,1.7,0L4.469,61.2,9.949,55.55a1.176,1.176,0,0,1,1.7,0A1.265,1.265,0,0,1,11.648,57.3Z" :fill="session.elementTextActiveColor"/>
                  </g>
                </g>
              </svg>
              
              <svg v-if="!isChecked(answer)" xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
                <g id="checkbox-off" fill="none" :stroke="session.elementTextColor" stroke-width="2">
                  <rect width="22" height="22" rx="5" stroke="none"/>
                  <rect x="1" y="1" width="20" height="20" rx="4" fill="none"/>
                </g>
              </svg>              


              <input :disabled="voteSended || voteInprogress" type="checkbox" v-model="selectedCheckboxAnswers" :value="answer.id">

              <span class="ms-2" v-if="isChecked(answer)" :style="[{'color':session.elementTextActiveColor}]">{{answer.text}}</span>
              <span class="ms-2" v-if="!isChecked(answer)" :style="[{'color':session.elementTextColor}]">{{answer.text}}</span>
            
              
            </label>
          </div>
        
          

        <div v-if="error" class="alert alert-danger" role="alert">
          {{error}}
        </div>
        
        <div v-if="documentSocket.documentData.question.weightCounted" class="text-end">
          <div class="help mb-3" :style="[{'color':session.helpColor}]">{{$t("qcm.vote_weight", {weight:voter.weight})}}</div>
        </div>

        <div v-if="voteSended" class="text-center">
          <button type="button" class="btn" @click="voteSended = false" :style="[{'color':session.elementTextActiveColor},{'background-color':session.elementBackgroundActiveColor}]">{{$t("qcm.change_label")}}</button>
        </div>
        
        <div class="text-end mb-3">
          <button v-if="!voteSended" :disabled="isSubmitDisabled || voteInprogress" type="submit" class="btn" :style="[{'color':session.elementTextActiveColor},{'background-color':session.elementBackgroundActiveColor}]">
            <span v-if="voteInprogress" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                <span v-if="!voteInprogress">{{$t("qcm.send_label")}}</span>
            </button>
        </div>
        <div class="text-end mb-3">
          <button v-if="!voteSended && documentSocket.documentData.question.absenceCounted" type="button" class="btn btn-light" :style="[{'color':session.elementTextColor}]" @click="abstention()">{{documentSocket.documentData.question.labelAbstention}}</button>
        </div>


      </form>
    </div>

    <div class="row mt-3">
      <div class="col col-6 text-start mb-3">
        <button v-if="documentSocket.documentData.question.previousButtonVisible" type="button" class="btn btn-primary btn-previous" :style="[{'color':session.elementTextActiveColor},{'background-color':session.elementBackgroundActiveColor}]" @click="previous()" :title="$t('qcm.previous_label')">
          <span v-if="previousButtonPushed" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          <span v-if="!previousButtonPushed">&lt;</span>
        </button>
      </div>

      <div class="col col-6 text-end mb-3">
        <button v-if="documentSocket.documentData.question.nextButtonVisible" :disabled="!voteSended" type="button" class="btn btn-primary btn-next" :style="[{'color':session.elementTextActiveColor},{'background-color':session.elementBackgroundActiveColor}]" @click="next()" :title="$t('qcm.next_label')">
          <span v-if="nextButtonPushed" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          <span v-if="!nextButtonPushed">&gt;</span>
        </button>
      </div>
    </div>    

    <div class="toast-container position-fixed bottom-0 end-0 p-3">
      <div id="voteRegisteredToast" class="toast align-items-center" role="alert" aria-live="assertive" aria-atomic="true" data-bs-delay="2000">
        <div class="d-flex">
          <div class="toast-body">
            <div class="message" :style="[{'color':session.textColor}]">{{$t("qcm.vote_registered")}}</div>
          </div>
          <button type="button" class="btn-close me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>        
      </div>
    </div>

  </div>

  

  
</template>

<script>
import voteService from '@/services/voteService';
import socketConfig from '@/configs/socketConfig';
import { io } from 'socket.io-client';
import eventDispatcher from '@/eventDispatcher';
import socketService from '@/services/socketService';
import {Toast} from 'bootstrap';
import $ from 'jquery'

export default {
  name: 'QCMBasicMultipleComponent',
  data: function () {
    return {
      error: null,
      selectedCheckboxAnswers: [],
      voteSended: false,
      voteInprogress: false,
      previousButtonPushed: false,
      nextButtonPushed: false
    }
  },
  props: {
    documentSocket: Object,
    voter: Object,
    session: Object
  },
  watch: 
  { 
    documentSocket: function() {

      this.voteSended = false;
      this.setSocket(); 

    }
  },
  mounted() {
      this.voteRegisteredToast = new Toast($("#voteRegisteredToast"));
      this.setSocket(); 
      
  },
  deactivated() {


  }, 
  computed: {
    isSubmitDisabled() {
      if(this.documentSocket)
      {
        if(this.selectedCheckboxAnswers.length < this.documentSocket.documentData.question.minChoice || this.selectedCheckboxAnswers.length > this.documentSocket.documentData.question.maxChoice)
        {
          return true;
        }
      }
      return false;
    }
  },
  methods:
  {
    isChecked(answer)
    {
      return this.selectedCheckboxAnswers.includes(answer.id);
    },
    controlForm()
    {
      
      if(this.selectedCheckboxAnswers.length >= this.documentSocket.documentData.question.minChoice && this.selectedCheckboxAnswers.length <= this.documentSocket.documentData.question.maxChoice)
      {
        return true;
      }

      this.error = this.$t("qcm.error_respect_instructions");
      return false;
    },
    abstention()
    {
      this.selectedCheckboxAnswers = [];
      this.submit(true);
    },
    previous()
    {
      this.previousButtonPushed = true;
      voteService.previous(this.documentSocket.documentData.question).then(
          response => {
            //console.log(response);
            this.previousButtonPushed = false;
          },
          errors => {
            console.log(errors);
            this.previousButtonPushed = false;
          }
        );
    },
    next()
    {
      this.nextButtonPushed = true;
      voteService.next(this.documentSocket.documentData.question).then(
          response => {
            //console.log(response);
            this.nextButtonPushed = false;
          },
          errors => {
            console.log(errors);
            this.nextButtonPushed = false;
          }
        );
    },
    submit(force=false)
    {
      
      if(force || this.controlForm())
      {
        const vote = {
          question: this.documentSocket.documentData.question.id,
          answer: (this.selectedCheckboxAnswers.length > 0) ? this.selectedCheckboxAnswers : [null]
        }
        this.voteInprogress = true;
        voteService.send(vote).then(
          response => {
            this.voteInprogress = false;
            this.voteSended = true;
            
            
            this.voteRegisteredToast.show();
            
            
          },
          errors => {
            this.voteInprogress = false;
            this.error = this.$t("qcm.error_while_registering_vote");
          }
        );
      }
    },
    setSocket()
    {

      this.selectedCheckboxAnswers = [];

      socketService.emit(socketConfig.JOIN_VOTE, this.documentSocket.documentData.question.id);
      socketService.emit(socketConfig.GET_MY_VOTES, this.voter.id, this.documentSocket.documentData.question.id);

      eventDispatcher.off(socketConfig.SEND_MY_VOTES);
      eventDispatcher.on(socketConfig.SEND_MY_VOTES, (votes) => {
        
        if(votes)
        {
          this.voteSended = true;
          var answers = [];
          for(const vote of votes)
          {
            answers.push(vote.answer);
          }
          this.selectedCheckboxAnswers = answers;
        }else
        {
          this.voteSended = false;
          this.selectedCheckboxAnswers = [];
        }
      });

      eventDispatcher.on(socketConfig.RELOAD_MY_VOTES, () => {
        socketService.emit(socketConfig.GET_MY_VOTES, this.voter.id, this.documentSocket.documentData.question.id);
      });
      
      
      
    }
  }
}

</script>


<style scoped lang="scss">
#page {
  display: flex;
  align-items: center;
  justify-content: center;

}

.bar {
  flex-wrap: initial !important;
}

.bar h6 {
  color: white;
}

.question {
  font-size: 20px;

}

.radio, .checkbox {
  background-color: #F5F5F5;
  padding: 15px;
  border-radius: 20px;
}

.radio.active, .checkbox.active {
  background-color: black;
}

label {
  display: block;
}

label.radio input,  label.checkbox input {
  display: none;
}

label.radio span, label.checkbox span {
  line-height: 22px;
  font-size: 20px;
}


label.radio.active , label.checkbox.active  {
  color: white;
}

label.disabled {
  opacity: 0.5;
}

.help {
  font-size: 14px;
  font-style: italic;
  color: #666666;
}

.message {
  font-size: 16px;
  font-weight: 700;
}

.btn-previous, .btn-next {
  border-radius: 60px;
  width: 50px;
  height: 50px;
  font-size: 20px;
  font-weight: 800;
}

</style>