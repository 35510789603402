<template>
  <div class="view p-0 m-0">
    <div class="left p-0 p-md-4">
      <iframe id="player" :src="vimeoUrl()" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen :width="videoWidth" :height="videoHeight"></iframe>
    </div>
    <div class="right">
      <iframe class="session" :src="'/'+$route.params.sessionSlug+'/'+$route.params.channelSlug+'/'+$route.params.externalId" frameborder="0"></iframe>
       
    </div>
  </div>
</template>


<script>
import $ from "jquery";
import Player from '@vimeo/player';

export default 
  {
  title(){
    if(this.session)
    {
      return this.session.name;
    }else
    {
      return process.env.VUE_APP_TITLE;
    }
  },
  name: 'LiveSessionView',
  data: function () {
    return {
      videoReady: false,
      videoWidth: null,
      videoHeight: null
    }
  },
  mounted() {
      
        this.resize();

        $( window ).on( "resize", () => {
          this.resize();
        });

        const player = new Player("player");
      
      
  },
  methods: {
    vimeoUrl()
    {
      return "https://vimeo.com/event/"+this.$route.params.vimeoId+"/embed/"+this.$route.params.vimeoH;
    },
    resize(){
      this.videoReady = false;

      setTimeout(()=> {
        var w = $("body").width();
        if(w > 991)
        {
          if((w/3) < 480)
          {
            this.videoWidth = w-480;
          }else if((w/3) > 720)
          {
            this.videoWidth = w-720;
          }else
          {
            this.videoWidth = $("body").width()/3*2;
          }
          
          this.videoHeight = "100%";
        }else
        {
          this.videoWidth = "100%";
          this.videoHeight = $("body").height()/3;
        }
        this.videoReady = true;
      }, 100);
      
    }
  }
}
</script>
<style lang="scss" scoped>
.view {
  width: 100%;
  height: 100%;
  display: flex;
}
.right {
  flex: 1;
}
.left {
  background-color: black;
}
.session {
  height: 100% !important;
  width: 100% !important;
}

@media (max-width: 991px) { 
  .view {
   flex-direction: column;
  }  
  #player {

  }
 }
</style>