import apiService from './apiService';

export default class voteService {



    static getMyId () {
        
        return new Promise((resolve, reject) => {
            
            apiService.get("/voter/getMyId")
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
         });
       
    }



    static lostPassword (voter) {
        
        return new Promise((resolve, reject) => {
            
            apiService.post("/voter/lostPassword", voter, false)
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
         });
       
    } 

    static changePassword (password, token) {
        const data = {
            password: password,
            token: token
        }
        return new Promise((resolve, reject) => {
            
            apiService.post("/voter/passwordChange", data, false)
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
         });
       
    }   
    
    static signupConfirm (password, token) {
        const data = {
            password: password,
            token: token
        }
        return new Promise((resolve, reject) => {
            
            apiService.post("/voter/signupConfirm", data, false)
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
         });
       
    }   
    
    static isVoterExists (token) {
        const data = {
            token: token
        }
        return new Promise((resolve, reject) => {
            
            apiService.post("/voter/isVoterExists", data, false)
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
         });
       
    }       


}
