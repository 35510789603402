<template>
 
  
    <div class="row w-100 after-navbar">
      <div class="col col-12 col-sm-10 col-md-9 col-lg-8 col-xl-6 col-xxl-5 mx-auto">
        <div v-if="documentSocket" class="image text-center">
          <img class="w-50" :src="documentSocket.documentData.data.image" />
        </div>
        <div v-if="documentSocket" class="title text-center mt-3 mb-3">
          <span v-html="documentSocket.documentData.data.title" :style="[{'color':session.textColor}]"></span>
        </div>
        <div v-if="documentSocket" class="text text-center">
          <span v-html="documentSocket.documentData.data.text" :style="[{'color':session.textColor}]"></span>
        </div>

      </div>

    </div>
    





</template>

<script>

export default {
  name: 'ScreenTitlaTextImageComponent',
  data: function () {
    return {
      error: null
    }
  },
  props: {
    documentSocket: Object,
    session: Object
  },
  mounted()
  {
  
  },
  methods:
  {
    
  }
}

</script>



<style scoped lang="scss">
#page {
  display: flex;
  align-items: center;
  justify-content: center;

}

.bar {
  flex-wrap: initial !important;
}

.bar h6 {
  color: white;
}


</style>