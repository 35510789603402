<template>
    <qcm-basic-single-component v-if="documentSocket && documentSocket.documentData.question.maxChoice == 1" :voter="voter" :documentSocket="documentSocket" :session="session" />
    <qcm-basic-multiple-component v-if="documentSocket && documentSocket.documentData.question.maxChoice > 1" :voter="voter" :documentSocket="documentSocket" :session="session" />
</template>

<script>
import QCMBasicSingleComponent from '@/components/question/QCMBasicSingleComponent.vue';
import QCMBasicMultipleComponent from '@/components/question/QCMBasicMultipleComponent.vue';


export default {
  name: 'QCMBasicComponent',
  components: {
    'qcm-basic-single-component': QCMBasicSingleComponent,
    'qcm-basic-multiple-component': QCMBasicMultipleComponent,
  },
  props: {
    documentSocket: Object,
    voter: Object,
    session: Object
  }
}

</script>