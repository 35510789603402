<template>
     <div class="row w-100 after-navbar">
      
      <result-basic-component v-if="documentSocket && documentSocket.documentData.question.resultTemplate.componentName == 'ResultBasicComponent'" :documentSocket="documentSocket" :voter="voter" :session="session" />
    </div>
    
</template>

<script>
import ResultBasicComponent from '@/components/question/ResultBasicComponent.vue'


export default {
  name: 'ResultComponent',
  components: {
    'result-basic-component': ResultBasicComponent
  },
  props:
  {
    documentSocket: Object,
    voter: Object,
    session: Object
  }
}

</script>