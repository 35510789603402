<template>
    <nav v-if="session" class="nav-main navbar navbar-expand-sm fixed-top" :style="[{'background-color':session.navbarBackgroundColor}]">
      <div class="container-fluid bar">
        <a class="navbar-brand">
          <img v-if="session.icon" :src="session.icon" width="40" height="40" alt="">
        </a>
        <h6 :style="[{'color':session.navbarTextColor}]">{{session.name}}</h6>    
        
        <div>
          <a @click="openModal()">
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
              <g id="ico-chat" transform="translate(0 0)">
                <path id="Path_7" data-name="Path 7" d="M141.652,137.313a1.08,1.08,0,0,1,.751.3,5.013,5.013,0,0,0,4.944,1.165,9.578,9.578,0,0,1-.4-7.339,12.6,12.6,0,0,0,.119-9.085,11.652,11.652,0,0,0-5.675-6.148,13.968,13.968,0,0,1-15.564,16.4,11.861,11.861,0,0,0,10.092,5.954,11.7,11.7,0,0,0,5.267-1.146A1.078,1.078,0,0,1,141.652,137.313Z" transform="translate(-115.861 -107.004)" :fill="session.chatIconColor" />
                <path id="Path_8" data-name="Path 8" d="M12.047,0A11.832,11.832,0,0,0,0,11.756a11.692,11.692,0,0,0,.931,4.625c.009.021.017.043.025.065a9.578,9.578,0,0,1-.4,7.339A5.013,5.013,0,0,0,5.5,22.62a1.081,1.081,0,0,1,1.217-.2,11.682,11.682,0,0,0,5.267,1.146A11.7,11.7,0,0,0,23.609,11.785,11.854,11.854,0,0,0,12.047,0ZM5.507,13.545a1.706,1.706,0,1,1,1.709-1.706A1.708,1.708,0,0,1,5.507,13.545Zm6.3,0a1.706,1.706,0,1,1,1.709-1.706A1.708,1.708,0,0,1,11.8,13.545Zm6.3,0a1.706,1.706,0,1,1,1.709-1.706A1.708,1.708,0,0,1,18.1,13.545Z" transform="translate(0 0)"  :fill="session.chatIconColor" />
              </g>
            </svg>
            <span v-if="unreadMessageForVoter() > 0" class="author-badge">
              {{unreadMessageForVoter()}}
            </span>
          </a>     
        </div>   
      </div>
    </nav>

  <div class="modal fade" id="chatModal" tabindex="-1">
    <div class="modal-dialog modal-dialog-slideout modal-lg">
      <div class="modal-content">
        <div class="modal-header d-flex">
          <h6 class="modal-title">{{$t("chat.ask_question")}}</h6>
          <button type="button" class="btn-close" @click="closeModal()" aria-label="Close"></button>
        </div>
        <div class="modal-body d-flex flex-column flex-grow-1">


          <div id="chatMessageContainer" class="flex-grow-1 d-flex flex-row">

            <div class="chat flex-grow-1 d-flex flex-column">
              <div class="messages">
                
                <div v-for="message in messages" v-bind:key="message.id" class="message d-flex"  :class="[{'from': message.voterFrom}, {'to': message.voterTo}]">
                    <div class="text flex-grow-1">
                      <span>
                        {{message.text}}
                      </span>
                    </div>
                  </div>

                
              </div>

              <div class="form">
                <form  @submit.prevent="sendMessage" class="d-flex">
                  <input class="flex-grow-1" type="text" :placeholder="$t('chat.your_message')" v-model="messageToSend">
                  <button type="submit"><span>Send</span></button>
                </form>
              </div>      
            </div>
          </div>


        </div>
      </div>
    </div>
  </div>

</template>

<script>

import voterService from '@/services/voterService';
import messageService from '@/services/messageService';
import {Modal} from 'bootstrap';
import $ from 'jquery'
import eventDispatcher from '@/eventDispatcher';
import socketConfig from '@/configs/socketConfig';
import socketService from '@/services/socketService';

export default {
  name: 'NavbarComponent',
  data: function () {
    return {
      error: null,
      messages: [],
      socket: null,
      messageToSend: null,
      voter: null,
      chatModal: null,
      modalOpened: false
    }
  },
  props: {
    session: Object
  },
  mounted()
  { 
    this.chatModal = new Modal($('#chatModal'));

    $(window).resize(()=>{
      $(".chat .messages").css('max-height', "0px" );
      this.resize();
    });
    this.getVoter();
    
  },
  methods:
  {
    markReadMessages()
    {
      var messages = this.markReadMessagesByCurrenVoter();
      messageService.markReadMessages( messages ).then(
        response => {
          
        },
        errors => {
          
        }
      );
    },
    sendMessage()
    {
      
      var message = {
        text: this.messageToSend,
        voterFrom: this.voter.id,
        voterFromFirstName: this.voter.firstName,
        voterFromLastName: this.voter.lastName,
        session: this.session.id
      }
      messageService.sendMessage( message ).then(
        response => {
          this.messageToSend = null;

          socketService.emit(socketConfig.GET_VOTER_MESSAGES, this.voter.id);
        },
        errors => {
          
        }
      );
    },
    getVoter() {
      
        voterService.getMyId().then(
            response => {
              this.voter = response;
              
              this.setSocket();
            },
            errors => {
              
            }
        );
      

    },
    closeModal() {
      this.chatModal.hide();
      this.modalOpened = false;
    },
    openModal() {
      this.markReadMessages();
      this.chatModal.show();
      this.modalOpened = true;
      
      setTimeout(() => {
        this.resize();  
      }, 300);

      setTimeout(() => {
        this.scrollBottom();  
      }, 300);
    },
    resize() {
      var messageContainerHeight = $("#chatMessageContainer").height();
      var formHeight = $(".form").height();
      
      $(".chat .messages").css('max-height', (messageContainerHeight-formHeight)+"px" );
      $(".chat .messages").css('min-height', (messageContainerHeight-formHeight)+"px" );
    }, 
    markReadMessagesByCurrenVoter() {
      var messages = [];
      for(var message of this.messages)
      {
        if(message.voterFrom == this.voter.id || message.voterTo == this.voter.id)
        {
          message.readAt = Date.now();
          messages.push(message);
        }
      }

      return messages;
    },
    unreadMessageForVoter() {
      var unreadMessagesNumber = 0;

      for(var message of this.messages)
      {
        if(message.voterTo == this.voter.id)
        {
          if(!message.readAt)
          {
            unreadMessagesNumber++;
          }
        }
      }

      return unreadMessagesNumber;
    }, 
    initialsOf(firstName, lastName) {
      if(firstName && lastName)
      {
        return firstName.charAt(0).toUpperCase()+lastName.charAt(0).toUpperCase();
      }
      return "";
    },  
    setSocket()
    {
      
      socketService.emit(socketConfig.GET_VOTER_MESSAGES, this.voter.id);
      
      
      eventDispatcher.off(socketConfig.SEND_VOTER_MESSAGES);
      eventDispatcher.on(socketConfig.SEND_VOTER_MESSAGES, (messages) => {
          
          this.messages = messages;
          setTimeout(() => {
            this.scrollBottom();  
          }, 300);
           
          if(this.modalOpened)
          {
            this.markReadMessagesByCurrenVoter();
          }
        
      });

      eventDispatcher.off(socketConfig.SEND_VOTER_MESSAGE);
      eventDispatcher.on(socketConfig.SEND_VOTER_MESSAGE, (message) => {
          this.messages.push(message);
          setTimeout(() => {
            this.scrollBottom();  
          }, 300);

          if(this.modalOpened)
          {
            this.markReadMessagesByCurrenVoter();
          }
        });
     
      
    },
    scrollBottom() {
      $(".chat .messages").animate({ scrollTop: $(".chat .messages").prop("scrollHeight") }, 500);
    }
  }
}

</script>

<style scoped lang="scss">

.bar {
  flex-wrap: initial !important;
}

.bar h6 {
  color: white;
}


.modal-dialog-slideout {min-height: 100%; margin: 0 0 0 auto;background: #fff;}
.modal.fade .modal-dialog.modal-dialog-slideout {-webkit-transform: translate(100%,0)scale(1);transform: translate(100%,0)scale(1);}
.modal.fade.show .modal-dialog.modal-dialog-slideout {-webkit-transform: translate(0,0);transform: translate(0,0);display: flex;align-items: stretch;-webkit-box-align: stretch;height: 100%;}
.modal.fade.show .modal-dialog.modal-dialog-slideout .modal-body{overflow-y: auto;overflow-x: hidden;}
.modal-dialog-slideout .modal-content{border: 0;}
.modal-dialog-slideout .modal-header, .modal-dialog-slideout .modal-footer {height: 69px; display: block;} 




.initial {
   width: 32px;
   height: 32px;
   line-height: 32px;
   text-align: center;
   background-color: #9A9A9A;
   display: inline-block;
   border-radius: 16px;
   font-size: 0.9em;
 }

.author-badge {
  background-color: black;
  color: white;
  width: 16px;
  height: 16px;
  display: block;
  line-height: 13px;
  padding: 2px;
  font-size: 0.8em;
  position: relative;
  margin-top: -12px;
  margin-left: 20px;
  border-radius: 8px;
  text-align: center;
}

.initial.active {
  background-color: black;
  color: white;
}

.chat .messages {
  padding-top: 5px;
  overflow-y: scroll;
  max-height: 0px;  
  padding-left: 10px;
  padding-right: 10px;
}

.message {
  margin-top: 8px;
  margin-bottom: 20px;
}


.message .text {
  background-color: #9A9A9A;
  padding: 10px;
  border-radius: 10px;
  border-top-left-radius: 0px;
  color: white;
}

.message.from .text {
  margin-left: 10px;
  background-color: black;
  padding: 10px;
  border-radius: 10px;
  border-bottom-right-radius: 0px;
  color: white;
}

.form {
  padding-left: 10px;
  padding-right: 10px;
}

.form form {
  background-color: #F5F5F5;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.form input, .form input:hover, .form input:focus, .form input:active  {
  border: none;
  background-color: #F5F5F5;
}

.form button {
  border: none;
  background-color: #F5F5F5;
  background-image: url(@/assets/images/ico-send.svg);
  background-repeat: no-repeat;
  background-position: center center;
  width: 30px;
  height: 30px;
}

.form button span {
  display: none;
}
</style>