<template>
    
    <div v-if="documentSocket && (voterIsInGroup() || noGroupForThisQuestion() )" class="row w-100 after-navbar">
      <qcm-basic-component v-if="documentSocket.documentData.question.voteTemplate.componentName == 'QCMBasicComponent'" :documentSocket="documentSocket" :voter="voter" :session="session" />
    </div>
    <div v-if="documentSocket && (!voterIsInGroup() && !noGroupForThisQuestion())" class="row w-100 after-navbar">
      <p class="text-center">{{ $t("vote.you_are_not_allowed_to_vote") }}</p>
    </div>
    
</template>

<script>

import QCMBasicComponent from '@/components/question/QCMBasicComponent.vue';
import sessionConfig from '@/configs/sessionConfig';

export default {
  name: 'VoteComponent',
  components: {
    'qcm-basic-component': QCMBasicComponent
  },
  data: function () {
    return {
      error: null,
      selectedRadioAnswer: null,
      selectedCheckboxAnswer: null
    }
  },
  props: {
    documentSocket: Object,
    voter: Object,
    session: Object
  },
  methods:
  {
    voterIsInGroup()
    {
      for(const docGroup of this.documentSocket.documentData.question.groups)
      {
        for(const voterGroup of this.voter.groups)
        {
          if(docGroup == voterGroup)
          {
            return true;
          }
        }

      }

      return false;
    },
    noGroupForThisQuestion()
    {
      if(this.documentSocket.documentData.question.groups.length == 0)
      {
        return true;
      }

      return false;
    }
  }
}

</script>
