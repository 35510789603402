<template>
  <qas-basic-component v-if="documentSocket && documentSocket.documentData.template.componentName == 'QasBasicComponent'" :documentSocket="documentSocket" :session="session" />
</template>

<script>
import QasBasicComponent from '@/components/qas/QasBasicComponent.vue';

export default {
  name: 'QasComponent',
  data: function () {
    return {
      error: null
    }
  },
  components:
  {
    'qas-basic-component': QasBasicComponent
  },
  props: {
    documentSocket: Object,
    session: Object
  },
  mounted()
  {
    
  }
}

</script>

<style scoped lang="scss">
</style>