import eventConfig from "@/configs/eventConfig";
import eventDispatcher from "@/eventDispatcher";
import i18n from "../i18n"

export default class languageService {

    static setLanguage (language) {
        i18n.global.locale = language;
    }



}
