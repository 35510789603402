import eventConfig from "@/configs/eventConfig";
import eventDispatcher from "@/eventDispatcher";
import socketConfig from '@/configs/socketConfig';

import { io } from 'socket.io-client';

export default class socketService {


    static connect () {
        this.socket = io(process.env.VUE_APP_SOCKET_URL);    

        this.socket.on(socketConfig.CONNECT_ERROR, () => {
            this.socket.isConnected = false;
            eventDispatcher.emit(socketConfig.CONNECT_ERROR);
        });

        this.socket.on(socketConfig.DISCONNECT, (reason) => {
            this.socket.isConnected = false;
            eventDispatcher.emit(socketConfig.DISCONNECT, reason);
            console.log("disconnect reason: "+reason);
            /*
            if ( (reason === "io server disconnect") ) {
                // the disconnection was initiated by the server, you need to reconnect manually
        
            }
            */
        });

        this.socket.on(socketConfig.CONNECT, () => {
            this.socket.isConnected = true;
            eventDispatcher.emit(socketConfig.CONNECT);
        });


        this.socket.on(socketConfig.SEND_VOTER_MESSAGES, (messages) => {
            eventDispatcher.emit(socketConfig.SEND_VOTER_MESSAGES, messages);
        });

        this.socket.on(socketConfig.SEND_VOTER_MESSAGE, (message) => {
            eventDispatcher.emit(socketConfig.SEND_VOTER_MESSAGE, message);
        });

        this.socket.on(socketConfig.PILOT_SESSION, (documentSocket) => {
            eventDispatcher.emit(socketConfig.PILOT_SESSION, documentSocket);
        });

        this.socket.on(socketConfig.SEND_MY_VOTES, (votes) => {
            eventDispatcher.emit(socketConfig.SEND_MY_VOTES, votes);
        });

        this.socket.on(socketConfig.RELOAD_MY_VOTES, () => {
            eventDispatcher.emit(socketConfig.RELOAD_MY_VOTES);
        });


        this.socket.on(socketConfig.PILOT_VOTE, (stats) => {
            eventDispatcher.emit(socketConfig.PILOT_VOTE, stats);
        });

        

    }

    static emit (type, data, data2, data3) {
        this.socket.emit(type, data, data2, data3);
    }
    
    static isConnected () {
        return this.socket.isConnected;
    }

}
