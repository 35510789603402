<template>
  <div v-if="session" class="flex-grow-1 d-flex flex-column">
    <div v-if="documentSocket" class="messages">
      <div v-if="documentSocket.documentData.template.data.introduction" v-html="documentSocket.documentData.template.data.introduction" class="mb-3 text-center"></div>
      <div v-for="qa in qasVisible" v-bind:key="qa.id" class="message d-flex">
        <div>
          <div class="initial flex-shrink-0" :class="{'anonym': !qa.displayName}" :style="[{'background-color':session.elementBackgroundActiveColor},{'color':session.elementTextActiveColor}]">
            <span v-if="!qa.displayName">
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 22 22">
                <g id="ico-user-off" transform="translate(0 0)">
                  <g id="Group_7" data-name="Group 7" transform="translate(0 0)">
                    <path id="Path_10" data-name="Path 10" d="M18.778,3.222A11,11,0,0,0,3.222,18.778,11,11,0,0,0,18.778,3.222ZM11,20.711a9.689,9.689,0,0,1-7.238-3.245A7.736,7.736,0,0,1,11,12.461a3.867,3.867,0,1,1,3.867-3.867A3.867,3.867,0,0,1,11,12.461a7.736,7.736,0,0,1,7.238,5.005A9.689,9.689,0,0,1,11,20.711Z" transform="translate(0 0)"  :fill="session.elementTextActiveColor"/>
                  </g>
                </g>
              </svg>
            </span>
            <span v-if="qa.displayName">
                {{getInitial(qa)}}
            </span>
          </div>
          <div v-if="qa.displayName" class="name" :style="[{'color':session.textColor}]">
            {{ capitalizeFirstLetter(qa.firstName) }} {{ capitalizeFirstLetter(qa.lastName) }}
          </div>
        </div>
        <div class="text w-100" :style="[{'background-color':session.elementBackgroundActiveColor},{'color':session.elementTextActiveColor}]">
          <span>
            {{qa.text}}
          </span>
        </div>
      </div>
      

    </div>


  </div>
</template>

<script>

export default {
  name: 'QasBasicComponent',
  data: function () {
    return {
      error: null
    }
  },
  computed: {
      qasVisible: function () {
        return this.documentSocket.documentData.qas.filter(qa => qa.visible)
      }
  },
  props: {
    documentSocket: Object,
    session: Object
  },
  mounted()
  {
  
  },
  methods:
  {
    getInitial(qa)
    {
      return qa.firstName.charAt(0).toUpperCase()+' '+qa.lastName.charAt(0).toUpperCase();
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
  }
}

</script>

<style scoped lang="scss">
a {
  text-decoration: none;
}


.initial {
  width: 32px;
  height: 32px;
  line-height: 29px;
  text-align: center;
  color: white;
  background-color: black;
  display: inline-block;
  border-radius: 16px;
  font-size: 0.9em;
 }


.name {
  font-size: 12px;
  margin-top: 5px;
}

.messages {
  padding-top: 5px;
  overflow-y: scroll;
  padding-left: 10px;
  padding-right: 10px;
}

.message {
  margin-top: 8px;
  margin-bottom: 20px;
}


.message .text {
  margin-left: 10px;
  background-color: black;
  padding: 10px;
  border-radius: 10px;
  border-top-left-radius: 0px;
  color: white;
}
</style>