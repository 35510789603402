<template>
  <router-view v-slot="{ Component }">
    <component :is="Component" />
  </router-view>
  <MarkComponent :visible="markVisible" />
</template>

<script>
import MarkComponent from './components/MarkComponent.vue'

export default {
  name: 'App',
  data: function () {
    return {
      markVisible: (this.$route.meta.hasMark)
    }
  },
  components: {
    MarkComponent
  }
}
</script>